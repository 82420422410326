import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Form, Modal, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import checkDomain from 'lib/checkDomain';

import Button from 'ui/Button';
import FormField from 'ui/FormField';

import { selectState, saveHost } from './reducer';

let domainCheck;

const initialState = {
  name: '',
  domain: '',
  changeIP: false,
  ip: '',
};

const Edit = (props) => {
  const { host, show, onCancel } = props;
  const dispatch = useDispatch();
  const state = useSelector(selectState);
  const [edited, setEdited] = useState(false);

  const onChange = useCallback(() => {
    setEdited(true);
  }, []);

  const doCancel = useCallback(() => {
    if (state.saving) {
      return;
    }

    onCancel();
  }, [state, onCancel]);

  const save = useCallback(
    (values) => {
      const saveData = {
        id: host.id,
        ...values,
      };
      dispatch(
        saveHost(saveData, () => {
          onCancel();
        })
      );
    },
    [dispatch, onCancel, host]
  );

  const initialValues = useMemo(() => {
    const vals = { ...initialState, ...host };
    let { name, ip } = vals;
    let changeIP = false;
    const domain = 'ns3.me';

    if (name) {
      name = name.split('.').shift();
    }

    if (vals.Records && vals.Records.length > 0) {
      ip = vals.Records[0].ip;
    }

    if (!host.id) {
      changeIP = true;
    }

    if (!ip && state.user && state.user.ip) {
      ip = state.user.ip;
    }

    return {
      name,
      domain,
      ip,
      changeIP,
    };
  }, [host, state]);

  const eventSchema = useMemo(() => {
    return yup.object().shape({
      name: yup
        .string()
        .matches(
          /^[a-z]+[a-z0-9-][a-z0-9]+$/,
          'Domains must: only contain lowercase letters, numbers, and hyphens; start with a letter; end with a letter or number.'
        )
        .min(3, 'Domains must be at least 3 characters long')
        .max(255, 'Domains may only be 128 characters long')
        .required('Domain is required')
        .test('checkDomain', 'This domain is already in use', async (value) => {
          if (!value) {
            return false;
          }
          clearTimeout(domainCheck);
          return new Promise((resolve) => {
            domainCheck = setTimeout(async () => {
              try {
                const isUsed = await checkDomain(
                  value,
                  host && host.id ? host.id : null
                );
                return resolve(!isUsed);
              } catch (ex) {
                return resolve(false);
              }
            }, 300);
          });
        }),
      ip: yup.string().when('changeIP', {
        is: true,
        then: yup
          .string()
          .required('IP Address is required')
          .matches(
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            'Enter a valid IP address'
          ),
      }),
      changeIP: yup.boolean(),
    });
  }, [host]);

  return (
    <Modal show={show} onHide={doCancel} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{host.id ? 'Edit' : 'Add'} Host</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={eventSchema}
        onSubmit={save}
      >
        {(formProps) => {
          const isValid = formProps.isValid && formProps.dirty;
          return (
            <form onSubmit={formProps.handleSubmit} onInput={onChange}>
              <Modal.Body>
                {state.error && <Alert variant="danger">{state.error}</Alert>}
                <FormField
                  required
                  autoFocus
                  name="name"
                  label="Domain"
                  minLength="3"
                  maxLength="128"
                  value={formProps.values.name}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  append={
                    <InputGroup.Text>.{initialValues.domain}</InputGroup.Text>
                  }
                />
                {(!host.id || formProps.values.changeIP) && (
                  <FormField
                    required
                    name="ip"
                    label="IP Address"
                    value={formProps.values.ip}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                  />
                )}
                {host.id && (
                  <Form.Group>
                    <Form.Check
                      label="Update IP Address"
                      name="changeIP"
                      id="changeIP"
                      value={formProps.values.changeIP}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                    />
                  </Form.Group>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="light"
                  onClick={doCancel}
                  disabled={state.saving}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="success"
                  disabled={!edited || !isValid}
                  loading={state.saving}
                >
                  {host.id ? 'Save' : 'Add'} Host
                </Button>
              </Modal.Footer>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

Edit.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  // onSave: PropTypes.func.isRequired,
  host: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    ip: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    Records: PropTypes.array,
  }).isRequired,
};

export default Edit;
