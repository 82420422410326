import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Form, InputGroup } from 'react-bootstrap';

import FileField from './fields/File';
import DatetimeField from './fields/Datetime';

const specialFields = {
  file: FileField,
  datetime: DatetimeField,
};

const FormControlGroup = (props) => {
  let error = null;

  if (props.isInvalid) {
    error = (
      <Form.Control.Feedback type="invalid">
        {props.meta.error}
      </Form.Control.Feedback>
    );
  }

  if (!props.prepend && !props.append) {
    return (
      <>
        {props.children}
        {error}
      </>
    );
  };

  return (
    <InputGroup>
      {props.prepend && <InputGroup.Prepend>{props.prepend}</InputGroup.Prepend>}
      {props.children}
      {props.append && <InputGroup.Append>{props.append}</InputGroup.Append>}
      {error}
    </InputGroup>
  );
};

FormControlGroup.propTypes = {
  meta: PropTypes.any,
  children: PropTypes.any,
  isInvalid: PropTypes.bool,
};

FormControlGroup.defaultProps = {
  meta: {},
  children: undefined,
  isInvalid: false,
};

const FormControl = (props) => {
  const { value, ...fieldProps } = props;

  if (fieldProps.type && specialFields[fieldProps.type]) {
    const Field = specialFields[fieldProps.type];

    return (
      <FormControlGroup {...props}>
        <Field {...fieldProps} value={value} />
      </FormControlGroup>
    );
  }

  return (
    <FormControlGroup {...props}>
      <Form.Control {...fieldProps} value={value} />
    </FormControlGroup>
  );
};

FormControl.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
};

FormControl.defaultProps = {
  value: undefined,
};

const FormField = (props) => {
  const { label, helpText, value, ...otherProps } = props;

  const [field, meta] = useField(props);

  const hasError = useMemo(() => {
    return (meta.touched && meta.error) ? true : false;
  }, [meta]);

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label}
          {otherProps.required && (
            <span className="text-danger" title="Required">
              &nbsp;*
            </span>
          )}
        </Form.Label>
      )}
      <FormControl
        {...field}
        {...otherProps}
        meta={meta}
        value={value}
        isInvalid={hasError}
      />
      {helpText && <Form.Text muted>{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormField.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  helpText: PropTypes.string,
  type: PropTypes.string,
};

FormField.defaultProps = {
  children: undefined,
  label: undefined,
  value: undefined,
  helpText: undefined,
  type: 'text',
};

export default FormField;
