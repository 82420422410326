import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import api from 'lib/api';

import { hostsReducer } from '../../reducer';

export const hostReducer = createSlice({
  name: 'host',
  initialState: {
    saving: false,
    error: null,
  },
  reducers: {
    saveStart: (state) => {
      return {
        ...state,
        saving: true,
        error: null,
      };
    },
    saveSuccess: (state) => {
      return {
        ...state,
        saving: false,
        error: null,
      };
    },
    saveError: (state, action) => {
      return {
        ...state,
        saving: false,
        error: action.payload,
      };
    },
  },
});

export const saveHost = (host, success) => (dispatch) => {
  dispatch(hostReducer.actions.saveStart());

  let url = '/hosts';

  if (host.id) {
    url = `${url}/${host.id}`;
  }

  api.post(url, host).then(
    (response) => {
      dispatch(hostReducer.actions.saveSuccess());
      dispatch(hostsReducer.actions.updateHost(response.host));
      success();
      toast.success('Host saved');
    },
    (error) => {
      dispatch(hostReducer.actions.saveError(error));
      toast.error('Error saving host');
    }
  );
};

// selector for grabbing the state for the login page
export const selectState = (state) => ({
  ...state.host,
  user: state.app.loggedIn,
});

export default hostReducer.reducer;
