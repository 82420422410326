import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

import Loader from 'ui/Loader';

import styles from './index.module.scss';

const CustomButton = (props) => {
  const { loading, children, className, disabled, type, ...btnProps } = props;

  const spanStyles = {
    [styles.hidden]: loading,
  };

  const btnStyles = {
    [styles.loading]: loading,
  };

  return (
    <Button
      type={type || 'button'}
      disabled={loading || disabled}
      className={classnames(btnStyles, className)}
      {...btnProps}
    >
      <span className={classnames(spanStyles)}>{children}</span>
      {loading && (
        <span className={styles.loader}>
          <Loader />
        </span>
      )}
    </Button>
  );
};

CustomButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string,
};

CustomButton.defaultProps = {
  loading: false,
  disabled: false,
  children: undefined,
  className: undefined,
  type: 'button',
};

export default CustomButton;
