import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { logout } from 'components/Login/reducer';

// import Icon from 'ui/Icon';

import { selectState } from 'reducer';

import styles from './AppNav.module.scss';

import logo from './logo.png';

const AppNav = () => {
  const dispatch = useDispatch();
  const appState = useSelector(selectState);

  const doLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="mb-lg-4 mb-2">
      <Container>
        <Navbar.Brand className={styles.logo} href="https://ns3.me">
          <img src={logo} alt="ns3" className="img-responsive mr-2" />
        </Navbar.Brand>
        <Navbar.Collapse id="main-nav">
          <Nav className="ml-auto">
            <a href="https://ns3.me/developers.html" className="nav-link">
              Developers
            </a>
            {appState.loggedIn === null && (
              <>
                {/* <LinkContainer to="/help">
                  <Nav.Link>Help</Nav.Link>
                </LinkContainer> */}
                <LinkContainer to="/register">
                  <Nav.Link>Register</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
              </>
            )}
            {appState.loggedIn !== null && (
              <>
                <LinkContainer to="/dashboard">
                  <Nav.Link>Dashboard</Nav.Link>
                </LinkContainer>
                {/* <LinkContainer to="/help">
                  <Nav.Link>Help</Nav.Link>
                </LinkContainer> */}
                <Nav.Link onClick={doLogout}>Logout</Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNav;
