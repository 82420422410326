import { createSlice } from '@reduxjs/toolkit';

export const appReducer = createSlice({
  name: 'app',
  initialState: {
    loggedIn: null,
    error: null,
  },
  reducers: {
    loggedIn: (state, action) => {
      return {
        ...state,
        loggedIn: action.payload,
      };
    },
    error: (state, action) => {
      return {
        ...state,
        error: action.payload,
      };
    },
  },
});

export const selectState = (state) => state.app;

export default appReducer.reducer;
