import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';

import styles from './index.module.scss';

const Loader = (props) => {
  const { message, ...otherProps } = props;

  if (message) {
    return (
      <div className={styles.loader}>
        <div>
          <Icon icon="spinner" fixedWidth spin {...otherProps} />
          {message}
        </div>
      </div>
    );
  }

  return <Icon icon="spinner" fixedWidth spin {...otherProps} />;
};

Loader.propTypes = {
  message: PropTypes.string,
};

Loader.defaultProps = {
  message: null,
};

export default Loader;
