import { createSlice } from '@reduxjs/toolkit';

import api, { tokenName } from 'lib/api';

import { loginReducer } from 'components/Login/reducer';
import { appReducer } from 'reducer';

export const registerReducer = createSlice({
  name: 'register',
  initialState: {
    registered: false,
    registering: false,
    error: null,
  },
  reducers: {
    start: (state) => {
      return {
        ...state,
        registering: true,
        registered: false,
        error: null,
      };
    },
    success: (state) => {
      return {
        ...state,
        registering: false,
        registered: true,
        error: null,
      };
    },
    error: (state, action) => {
      return {
        ...state,
        registering: false,
        registered: null,
        error: action.payload,
      };
    },
  },
});

export const register = (userInfo) => (dispatch) => {
  // let stuff know what we're up to
  dispatch(registerReducer.actions.start());

  api.post('/users/register', userInfo).then(
    (response) => {
      // save the token in local storage so we can make requests
      if (response.token) {
        localStorage.setItem(tokenName, response.token);
      }
      // store the data we get back in the 'user' property as the
      // logged in user
      dispatch(appReducer.actions.loggedIn(response));
      dispatch(loginReducer.actions.success());
      dispatch(registerReducer.actions.success());
    },
    (error) => {
      dispatch(registerReducer.actions.error(error));
    }
  );
};

// selector for grabbing the state for the login page
export const selectState = (state) => state.register;

export default registerReducer.reducer;
