import React, { useEffect, useCallback, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import checkEmail from 'lib/checkEmail';

import Button from 'ui/Button';
import FormField from 'ui/FormField';
import PageTitle from 'ui/PageTitle';

import { selectState as selectAppState } from 'reducer';
import { register, selectState } from './reducer';
import { autoLogin } from '../Login/reducer';

import styles from './Register.module.scss';

let emailCheck;

// initial values for the form
const initialState = {
  email: '',
  password: '',
  agree: false,
};

const eventSchema = yup.object().shape({
  email: yup
    .string()
    .email('Enter a valid email address')
    .required('Email is required')
    .test('checkEmail', 'This email is already in use', async (value) => {
      if (!value || value.indexOf('@') === -1) {
        return false;
      }
      clearTimeout(emailCheck);
      return new Promise((resolve) => {
        emailCheck = setTimeout(async () => {
          try {
            const isUsed = await checkEmail(value);
            return resolve(!isUsed);
          } catch (ex) {
            return resolve(false);
          }
        }, 300);
      });
    }),
  password: yup
    .string()
    .min(8, 'Passwords must be at least 8 characters')
    .required('Password is required'),
});

const Register = () => {
  const dispatch = useDispatch();
  const state = useSelector(selectState);
  const appState = useSelector(selectAppState);
  const [edited, setEdited] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [localRegistering, setLocalRegistering] = useState(false);

  useEffect(() => {
    dispatch(autoLogin());
  }, []);

  const onChange = () => {
    setEdited(true);
  };

  const doRegister = useCallback(
    (values) => {
      if (!agreed) {
        // eslint-disable-next-line no-alert
        alert('You must agree to the terms');
        return;
      }

      const captcha = window.grecaptcha;
      if (!captcha) {
        return;
      }

      setLocalRegistering(true);
      captcha.ready(() => {
        captcha
          .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
            action: 'register',
          })
          .then((token) => {
            const vals = {
              ...values,
              agreed,
              token,
            };
            setLocalRegistering(false);
            dispatch(register(vals));
          });
      });
    },
    [dispatch, agreed]
  );

  const toggleAgreed = useCallback((e) => {
    setAgreed(e.target.checked);
  }, []);

  if (appState.loggedIn || state.registered) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Row>
      <Col sm={{ offset: 3, span: 6 }}>
        <PageTitle title="Register" />
        <Formik
          initialValues={initialState}
          validationSchema={eventSchema}
          onSubmit={doRegister}
        >
          {(formProps) => {
            const isValid = formProps.isValid && formProps.dirty;
            return (
              <form onSubmit={formProps.handleSubmit} onInput={onChange}>
                {state.error && <Alert variant="danger">{state.error}</Alert>}
                <FormField
                  required
                  name="email"
                  label="Email Address"
                  value={formProps.values.email}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                />
                <FormField
                  required
                  minLength="8"
                  name="password"
                  type="password"
                  label="Password"
                  value={formProps.values.password}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                />
                <input
                  required
                  type="checkbox"
                  id="agreeToTerms"
                  checked={agreed}
                  onChange={toggleAgreed}
                />{' '}
                <label htmlFor="agreeToTerms">
                  I agree to the{' '}
                  <a
                    href="https://ns3.me/terms.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Use
                  </a>
                  .
                </label>
                <div className={styles.buttons}>
                  <Link to="/">Return to login</Link>{' '}
                  <Button
                    type="submit"
                    disabled={!edited || !isValid || !agreed}
                    loading={state.registering || localRegistering}
                    className="ml-auto"
                  >
                    Register
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </Col>
    </Row>
  );
};

export default Register;
