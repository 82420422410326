import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Alert, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'ui/Button';
import Icon from 'ui/Icon';
import Loader from 'ui/Loader';
import PageTitle from 'ui/PageTitle';

// import { selectState as selectAppState } from 'reducer';
import { getHosts, deleteHost, selectState } from './reducer';

import Edit from './components/Edit';
import HostRecord from './components/HostRecord';

const MAX_HOSTS = 10;

const Hosts = () => {
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const [editHost, setEditHost] = useState({});
  // const appState = useSelector(selectAppState);
  const state = useSelector(selectState);

  useEffect(() => {
    dispatch(getHosts());
  }, [dispatch]);

  const showAddHost = useCallback(() => {
    setEditHost({});
    setShowEdit(true);
  }, []);

  const showEditHost = useCallback((host) => {
    setEditHost(host);
    setShowEdit(true);
  }, []);

  const saveHost = useCallback(() => {
    setShowEdit(false);
  }, []);

  const hideEdit = useCallback(() => {
    setShowEdit(false);
  }, []);

  const onDeleteHost = useCallback(
    (host) => {
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          `Are you sure you want to delete the host "${host.name}"?`
        )
      ) {
        dispatch(deleteHost(host));
      }
    },
    [dispatch]
  );

  const hasHosts = useMemo(() => {
    return state.hosts.length > 0;
  }, [state.hosts]);

  const buttons = useMemo(() => {
    if (state.hosts.length === MAX_HOSTS) {
      return null;
    }

    return (
      <Button size="sm" variant="secondary" onClick={showAddHost}>
        <Icon icon="plus" /> Add Host
      </Button>
    );
  }, [showAddHost, state.hosts]);

  return (
    <>
      <PageTitle title="Hosts" buttons={buttons} />
      {state.loading && <Loader size="3x" />}
      {!state.loading && !hasHosts && (
        <>
          <Alert variant="info">
            <h4>You do not have any hosts</h4>
            <p className="mb-0">There are no hosts setup for your account.</p>
            <hr />
            <Button onClick={showAddHost}>Add Host</Button>
          </Alert>
        </>
      )}
      {hasHosts && (
        <Table responsive hover>
          <thead>
            <tr>
              <th>Domain</th>
              <th colSpan="2">IP Address</th>
            </tr>
          </thead>
          <tbody>
            {state.hosts.map((host) => (
              <HostRecord
                key={host.id}
                host={host}
                onEdit={showEditHost}
                onDelete={onDeleteHost}
              />
            ))}
          </tbody>
        </Table>
      )}
      <Edit
        show={showEdit}
        host={editHost}
        onCancel={hideEdit}
        onSave={saveHost}
      />
    </>
  );
};

export default Hosts;
