import { lazy } from 'react';

const Login = lazy(() => import('components/Login'));
const Register = lazy(() => import('components/Register'));
const Dashboard = lazy(() => import('components/Dashboard'));

const routes = [
  {
    path: '/',
    exact: true,
    component: Login,
    auth: false,
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    auth: false,
  },
  {
    path: '/register',
    exact: true,
    component: Register,
    auth: false,
  },
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
    auth: true,
  },
];

export default routes;
