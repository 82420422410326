import axios from 'axios';

// this is the name of the token in local storage that will be used to
// authenticate requests
export const tokenName = 'ns3token';

// the name of the header where we'll stuff the auth token
const headerName = 'x-ns3-auth';

// set the base URL on axios to the API endpoint base URL
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// intercept requests so we can do stuff to 'em
axios.interceptors.request.use(
  (config) => {
    const cfg = { ...config };
    const token = localStorage.getItem(tokenName);

    // if we have a token, use it so the request can be authenticated
    if (token) {
      cfg.headers[headerName] = token;
    }

    // tada!
    return cfg;
  },
  (error) => {
    // i die a slow and painful death
    return Promise.reject(error);
  }
);

// intercept responses so we can throw useful error messages
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data) {
        return response.data;
      }

      if (response.body) {
        return response.body;
      }
    }

    return response;
  },
  (error) => {
    // setup some generic BS error message
    let message = 'An unhandled error occurred.';

    // try to extract something a bit more meaningful if possible
    if (error.response) {
      if (error.response.body) {
        message = error.response.body;
      } else if (error.response.data && error.response.data.error) {
        message = error.response.data.error;
      }
    } else if (error.message) {
      message = error.message;
    }

    // we've done all we can... time of death?
    return Promise.reject(message);
  }
);

export default axios;
