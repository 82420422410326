import { createSlice } from '@reduxjs/toolkit';

import { appReducer } from 'reducer';

import api, { tokenName } from 'lib/api';

export const loginReducer = createSlice({
  name: 'login',
  initialState: {
    loggingIn: false,
    error: null,
  },
  reducers: {
    start: (state) => {
      return {
        ...state,
        loggingIn: true,
        error: null,
      };
    },
    success: (state) => {
      return {
        ...state,
        loggingIn: false,
        error: null,
      };
    },
    autoSuccess: (state) => {
      return {
        ...state,
        loggingIn: false,
        error: null,
      };
    },
    logout: (state) => {
      return {
        ...state,
        loggingIn: false,
      };
    },
    error: (state, action) => {
      return {
        ...state,
        loggingIn: false,
        error: action.payload,
      };
    },
  },
});

export const autoLogin = () => (dispatch) => {
  const token = localStorage.getItem(tokenName);

  if (!token) {
    return;
  }

  // let stuff know what we're up to
  dispatch(loginReducer.actions.start());

  // get the user's profile
  api.get('/users/me').then(
    (response) => {
      // save the token in local storage so we can make requests
      if (response.token) {
        localStorage.setItem(tokenName, response.token);
      }
      // store the data we get back in the 'user' property as the
      // logged in user
      dispatch(loginReducer.actions.autoSuccess());
      dispatch(appReducer.actions.loggedIn(response));
    },
    (error) => {
      localStorage.removeItem(tokenName);
      dispatch(loginReducer.actions.error(error));
    }
  );
};

export const login = (user) => (dispatch) => {
  // let stuff know what we're up to
  dispatch(loginReducer.actions.start());

  // try to login (or register). not much to see here.
  api.post('/users/login', user).then(
    (response) => {
      // save the token in local storage so we can make requests
      if (response.token) {
        localStorage.setItem(tokenName, response.token);
      }
      // store the data we get back in the 'user' property as the
      // logged in user
      dispatch(loginReducer.actions.success());
      dispatch(appReducer.actions.loggedIn(response));
    },
    (error) => {
      dispatch(loginReducer.actions.error(error));
      dispatch(appReducer.actions.loggedIn(null));
    }
  );
};

export const logout = () => (dispatch) => {
  localStorage.removeItem(tokenName);
  dispatch(loginReducer.actions.logout());
  dispatch(appReducer.actions.loggedIn(null));
};

// selector for grabbing the state for the login page
export const selectState = (state) => state.login;

export default loginReducer.reducer;
