import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import api from 'lib/api';

import { appReducer } from 'reducer';

export const accountReducer = createSlice({
  name: 'account',
  initialState: {
    saving: false,
    error: null,
  },
  reducers: {
    saveStart: (state) => {
      return {
        ...state,
        saving: true,
        error: null,
      };
    },
    saveSuccess: (state) => {
      return {
        ...state,
        saving: false,
        error: null,
      };
    },
    saveError: (state, action) => {
      return {
        ...state,
        saving: false,
        error: action.payload,
      };
    },
  },
});

export const saveAccount = (account, success) => (dispatch) => {
  dispatch(accountReducer.actions.saveStart());

  api.post('/users/me', account).then(
    (response) => {
      dispatch(accountReducer.actions.saveSuccess());
      dispatch(appReducer.actions.loggedIn(response));
      success(response);
      toast.success('Account saved');
    },
    (error) => {
      dispatch(accountReducer.actions.saveError(error));
      toast.success('Unable to save account');
    }
  );
};

export const selectState = (state) => {
  return {
    user: state.app.loggedIn.user,
    ...state.account,
  };
};

export default accountReducer.reducer;
