import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';
import Moment from 'react-moment';
import 'moment-timezone';

import Button from 'ui/Button';
import Icon from 'ui/Icon';

// import styles from './Host.module.scss';

const Host = (props) => {
  const { host, onEdit, onDelete } = props;

  const record = useMemo(() => {
    return host.Records ? host.Records[0] : null;
  }, [host]);

  const edit = useCallback(() => {
    onEdit(host);
  }, [onEdit, host]);

  const deleteHost = useCallback(() => {
    onDelete(host);
  }, [onDelete, host]);

  return (
    <tr>
      <td>
        <a
          href={`http://${host.name}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {host.name} <Icon icon="external-link-square" />
        </a>
        <div className="small">
          <Moment format="MMMM D, YYYY - h:mm A">{host.updatedAt}</Moment>
        </div>
      </td>
      <td>
        {record ? (
          <>
            {record.ip}
            <div className="small">
              <Moment format="MMMM D, YYYY - h:mm A">{record.createdAt}</Moment>
            </div>
          </>
        ) : (
          'N/A'
        )}
      </td>
      <td className="text-right">
        <ButtonGroup>
          <Button
            size="sm"
            variant="link"
            title="Edit"
            onClick={edit}
            disabled={record.deleting}
          >
            <Icon icon="edit" />
          </Button>
          {/* <Button size="sm" variant="link" title="Update">
            <Icon icon="sync" />
          </Button> */}
          <Button
            size="sm"
            variant="link"
            title="Delete"
            onClick={deleteHost}
            disabled={record.deleting}
          >
            <Icon
              icon={record.deleting ? 'sync' : 'trash'}
              spin={record.deleting}
              className="text-danger"
            />
          </Button>
        </ButtonGroup>
      </td>
    </tr>
  );
};

Host.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  host: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    ip: PropTypes.string,
    updatedAt: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    Records: PropTypes.array,
  }).isRequired,
};

export default Host;
