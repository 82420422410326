import React from 'react';
import PropTypes from 'prop-types';

import styles from './PageTitle.module.scss';

const PageTitle = (props) => {
  const { buttons, title } = props;

  return (
    <div className={styles.pageTitle}>
      <h3>{title}</h3>
      {buttons && <div className={styles.buttons}>{buttons}</div>}
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  buttons: PropTypes.any,
};

PageTitle.defaultProps = {
  buttons: null,
};

export default PageTitle;
