import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import api from 'lib/api';

export const hostsReducer = createSlice({
  name: 'hosts',
  initialState: {
    loading: false,
    hosts: [],
    error: null,
  },
  reducers: {
    start: (state) => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    },
    success: (state, action) => {
      return {
        ...state,
        loading: false,
        hosts: action.payload,
        error: null,
      };
    },
    error: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    updateHost: (state, action) => {
      let found = false;

      const hosts = state.hosts.map((h) => {
        if (h.id === action.payload.id) {
          found = true;
          return action.payload;
        }
        return h;
      });

      if (!found) {
        hosts.unshift(action.payload);
      }

      return {
        ...state,
        hosts,
      };
    },
    deleteStart: (state, action) => {
      return {
        ...state,
        error: null,
        hosts: state.hosts.map((h) => {
          const hHost = { ...h };
          if (hHost.id === action.payload.id) {
            hHost.deleting = true;
          }
          return h;
        }),
      };
    },
    deleteError: (state, { host, error }) => {
      return {
        ...state,
        error,
        hosts: state.hosts.map((h) => {
          const hHost = { ...h };
          if (hHost.id === host.id) {
            hHost.deleting = false;
          }
          return hHost;
        }),
      };
    },
    deleteSuccess: (state, action) => {
      return {
        ...state,
        error: null,
        hosts: state.hosts.filter((h) => h.id !== action.payload.id),
      };
    },
  },
});

export const getHosts = (userInfo) => (dispatch) => {
  dispatch(hostsReducer.actions.start());

  api.get('/hosts', userInfo).then(
    (response) => {
      dispatch(hostsReducer.actions.success(response.hosts));
    },
    (error) => {
      dispatch(hostsReducer.actions.error(error));
    }
  );
};

export const deleteHost = (host) => (dispatch) => {
  dispatch(hostsReducer.actions.deleteStart(host));

  api.delete(`/hosts/${host.id}`, host).then(
    () => {
      dispatch(hostsReducer.actions.deleteSuccess(host));
      toast.success('Host deleted');
    },
    (error) => {
      dispatch(hostsReducer.actions.deleteError({ host, error }));
      toast.error('Error deleting host');
    }
  );
};

// selector for grabbing the state for the login page
export const selectState = (state) => state.hosts;

export default hostsReducer.reducer;
