import React from 'react';
import ReactDOM from 'react-dom';

import { Modal } from 'react-bootstrap';

import Button from 'ui/Button';

const ConfirmDialog = (message, callback) => {
  const container = document.createElement('div');
  const msg = JSON.parse(message);

  container.setAttribute('custom-confirmation-navigation', '');

  document.body.appendChild(container);

  const closeModal = (response) => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
    callback(response);
  };

  ReactDOM.render(
    <Modal show animation={false}>
      <Modal.Header>
        <Modal.Title>{msg.title || 'Please Confirm'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="lead">{msg.message}</p>
        {msg.subMessage && <div className="text-muted">{msg.subMessage}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button autoFocus variant="light" onClick={() => closeModal(false)}>
          {msg.noButton ? msg.noButton : 'No'}
        </Button>
        <Button variant="danger" onClick={() => closeModal(true)}>
          {msg.yesButton ? msg.yesButton : 'Yes'}
        </Button>
      </Modal.Footer>
    </Modal>,
    container
  );
};

export default ConfirmDialog;
