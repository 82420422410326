import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import copyToClipboard from 'lib/copyToClipboard';
import checkEmail from 'lib/checkEmail';

import Button from 'ui/Button';
import FormField from 'ui/FormField';
import Icon from 'ui/Icon';
import PageTitle from 'ui/PageTitle';

import { selectState, saveAccount } from './reducer';

let emailCheck;

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Enter a valid email address')
    .required('Email address is required')
    .test('checkEmail', 'This email is already in use', async (value) => {
      if (!value || value.indexOf('@') === -1) {
        return false;
      }
      clearTimeout(emailCheck);
      return new Promise((resolve) => {
        emailCheck = setTimeout(async () => {
          try {
            const isUsed = await checkEmail(value);
            return resolve(!isUsed);
          } catch (ex) {
            return resolve(false);
          }
        }, 300);
      });
    }),
  password: yup.string().min(8, 'Password must be at least 8 characters'),
});

const Account = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const [edited, setEdited] = useState(false);
  const [copied, setCopied] = useState(false);

  const save = useCallback(
    (values, { setTouched, resetForm }) => {
      const saveData = {
        email: values.email,
        password: values.password,
      };
      dispatch(
        saveAccount(saveData, () => {
          setTouched({ email: false, password: false }, false);
          resetForm({ password: '' });
        })
      );
    },
    [dispatch]
  );

  const onChange = useCallback(() => {
    setEdited(true);
  }, []);

  const copyBtn = useMemo(() => {
    const copyApiKey = () => {
      copyToClipboard(state.user.apiKey).then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      });
    };

    return (
      <Button
        variant={copied ? 'success' : 'secondary'}
        disabled={copied}
        onClick={copyApiKey}
      >
        <Icon icon={copied ? 'check-circle' : 'clipboard'} />{' '}
        {copied ? 'Copied!' : 'Copy'}
      </Button>
    );
  }, [state.user.apiKey, copied]);

  return (
    <>
      <PageTitle title="Account" />
      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
      <Formik
        initialValues={{ ...state.user, password: '' }}
        validationSchema={schema}
        onSubmit={save}
      >
        {(formProps) => {
          const isValid = formProps.isValid && formProps.dirty;
          return (
            <form onSubmit={formProps.handleSubmit} onInput={onChange}>
              {state.error && <Alert variant="danger">{state.error}</Alert>}
              <FormField
                required
                type="email"
                name="email"
                label="Change Email Address"
                value={formProps.values.email}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
              />
              <FormField
                type="password"
                name="password"
                label="Change Password"
                minLength="8"
                value={formProps.values.password}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
              />
              <FormField
                readOnly
                name="apiKey"
                label="API Key"
                value={formProps.values.apiKey}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                append={copyBtn}
              />
              {formProps.dirty && (
                <div className="text-right">
                  <Button
                    type="submit"
                    variant="success"
                    disabled={!edited || !isValid}
                    loading={state.saving}
                  >
                    Save Account
                  </Button>
                </div>
              )}
            </form>
          );
        }}
      </Formik>
    </>
  );
};

Account.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  // user: PropTypes.object.isRequired,
};

export default Account;
