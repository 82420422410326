import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const AuthRoute = ({ component, ...props }) => {
  const Component = component;

  return (
    <Route
      {...props}
      render={(renderProps) => {
        if (props.auth && props.loggedIn === null) {
          return (
            <Redirect to={`/login?return=${renderProps.location.pathname}`} />
          );
        }

        return <Component {...renderProps} />;
      }}
    />
  );
};

AuthRoute.propTypes = {
  auth: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  loggedIn: PropTypes.any,
};

AuthRoute.defaultProps = {
  auth: false,
  loggedIn: null,
};

export default AuthRoute;
