import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Navbar, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import AuthRoute from 'components/AuthRoute';

import AppNav from 'ui/AppNav';
import Loader from 'ui/Loader';
import ConfirmDialog from 'ui/ConfirmDialog';

import { selectState } from 'reducer';
import routes from 'routes';

function App() {
  const state = useSelector(selectState);

  useEffect(() => {
    const head = document.getElementsByTagName('head')[0];
    const js = document.createElement('script');
    js.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    head.appendChild(js);
  }, []);

  return (
    <Router
      getUserConfirmation={(message, callback) => {
        return ConfirmDialog(message, callback);
      }}
    >
      <AppNav />
      <Container>
        <Suspense fallback={<Loader size="2x" />}>
          <Switch>
            {routes.map((route) => (
              <AuthRoute
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
                auth={route.auth}
                loggedIn={state.loggedIn}
              />
            ))}
          </Switch>
        </Suspense>
      </Container>
      <footer>
        <Navbar bg="light" variant="light" fixed="bottom" className="mt-4 py-3">
          <Container>
            <div className="text-muted small">
              &copy;{new Date().getFullYear()}{' '}
              <a
                href="http://www.plan8studios.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                Plan8 Studios
              </a>
              . All Rights Reserved.{' '}
              <a
                href="https://www.ns3.me/terms.html"
                target="_blank"
                rel="noreferrer noopener"
              >
                Terms of Use
              </a>
            </div>
          </Container>
        </Navbar>
      </footer>
      <ToastContainer position="bottom-center" />
    </Router>
  );
}

export default App;
