import React, { forwardRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';

import styles from './Datetime.module.scss';

const Datetime = (props) => {
  const { value, onChange, ...otherProps } = props;
  const [dateValue, setDateValue] = useState(
    value ? new Date(value) : '' // new Date()
  );

  const setValue = useCallback(
    (date) => {
      date.setMilliseconds(0);
      setDateValue(date);
      onChange({ target: { name: otherProps.name, value: date } });
    },
    [onChange, otherProps]
  );

  let calendarClassName = null;
  if (otherProps.showTimeSelect) {
    calendarClassName = styles.withTime;
  }

  const CustomInput = forwardRef(({ value: controlValue, onClick }, ref) => {
    const {
      minDate,
      maxDate,
      showTimeSelect,
      type,
      timeIntervals,
      ...fieldProps
    } = otherProps;

    return (
      <Form.Control
        defaultValue={controlValue}
        ref={ref}
        onFocus={onClick}
        {...fieldProps}
      />
    );
  });

  CustomInput.propTypes = {
    onClick: PropTypes.func,
  };

  CustomInput.defaultProps = {
    onClick: () => {},
  };

  const className = otherProps.isInvalid ? 'is-invalid' : null;

  return (
    <div className={className}>
      <DatePicker
        calendarClassName={calendarClassName}
        selected={dateValue}
        onChange={setValue}
        dateFormat="MM/dd/yyyy h:mm aa"
        customInput={<CustomInput />}
        {...otherProps}
      />
    </div>
  );
};

Datetime.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  // isInvalid: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Datetime.defaultProps = {
  value: undefined,
  // isInvalid: false,
  showTimeSelect: false,
};

export default Datetime;
