import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library, findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faSpinner,
  faEdit,
  faTrash,
  faQuestionCircle,
  faExclamationTriangle,
  faCheckSquare,
  faCog,
  faSync,
  faClipboard,
  faCheckCircle,
  faPlus,
  faExternalLinkSquare,
  faServer,
  faSparkles,
  faDogLeashed,
  faLaptopCode,
  faDownload,
  faRocket,
} from '@fortawesome/pro-light-svg-icons';

import {
  faLinux,
  faApple,
  faWindows,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faSpinner,
  faEdit,
  faTrash,
  faQuestionCircle,
  faExclamationTriangle,
  faCheckSquare,
  faCog,
  faSync,
  faClipboard,
  faCheckCircle,
  faPlus,
  faExternalLinkSquare,
  faServer,
  faSparkles,
  faDogLeashed,
  faLaptopCode,
  faDownload,
  faLinux,
  faApple,
  faWindows,
  faRocket
);

const prefixes = {
  light: 'fal',
  solid: 'fas',
  brand: 'fab',
};

const Icon = (props) => {
  const { type, icon, ...otherProps } = props;

  let iconType = 'fal';

  if (type) {
    iconType = prefixes[type] || 'fal';
  }

  const iconLookup = { prefix: iconType, iconName: icon };
  const iconDef = findIconDefinition(iconLookup);

  return <FontAwesomeIcon icon={iconDef} {...otherProps} />;
};

Icon.propTypes = {
  icon: PropTypes.string,
  type: PropTypes.string,
};

Icon.defaultProps = {
  icon: 'question-circle',
  type: 'light',
};

export default Icon;
