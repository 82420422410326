import { configureStore } from '@reduxjs/toolkit';

import app from './reducer';
import { reducer as login } from './components/Login';
import { reducer as register } from './components/Register';
import { reducer as hosts } from './components/Dashboard/components/Hosts';
import { reducer as host } from './components/Dashboard/components/Hosts/components/Edit';
import { reducer as account } from './components/Dashboard/components/Account';

export default configureStore({
  reducer: {
    app,
    login,
    register,
    hosts,
    host,
    account,
  },
});
