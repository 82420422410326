import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import styles from './File.module.scss';

const FileField = (props) => {
  const { value, isInvalid, ...fieldProps } = props;
  const fileField = useRef(null);

  const showFiles = useCallback(() => {
    fileField.current.click();
  }, []);

  return (
    <div className={styles.fileUploader}>
      <button type="button" className={styles.filePreview} onClick={showFiles}>
        {value || <span className={styles.placeholder}>Select A File</span>}
      </button>
      <Button variant="secondary" onClick={showFiles}>
        Browse...
      </Button>
      <input type="file" ref={fileField} {...fieldProps} />
    </div>
  );
};

FileField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  isInvalid: PropTypes.bool,
};

FileField.defaultProps = {
  value: undefined,
  isInvalid: false,
};

export default FileField;
