/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Row, Col, Form } from 'react-bootstrap';
import qs from 'qs';

import Button from 'ui/Button';
import PageTitle from 'ui/PageTitle';

import { selectState as selectAppState } from 'reducer';
import { login, autoLogin, selectState } from './reducer';

import styles from './Login.module.scss';

const Login = (props) => {
  const dispatch = useDispatch();
  const state = useSelector(selectState);
  const appState = useSelector(selectAppState);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { location } = props;

  useEffect(() => {
    dispatch(autoLogin());
  }, [dispatch]);

  const doLogin = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  if (appState.loggedIn !== null) {
    let redir = '/dashboard';
    if (location.search && location.search.length > 1) {
      const params = qs.parse(location.search.slice(1));
      if (params.return && params.return.slice(0, 1) === '/') {
        redir = params.return;
      }
    }
    return <Redirect to={redir} />;
  }

  return (
    <Row>
      <Col sm={{ offset: 3, span: 6 }}>
        <PageTitle title="Login" />
        {state.error && <Alert variant="warning">{state.error}</Alert>}
        <Form onSubmit={doLogin}>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              disabled={state.loggingIn}
              value={email}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              disabled={state.loggingIn}
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <div className={styles.buttons}>
            <Link to="/register">Register new account</Link>{' '}
            <Button type="submit" loading={state.loggingIn} className="ml-auto">
              Login
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

Login.propTypes = {
  // eslint-disable-next-line react/require-default-props
  location: PropTypes.object,
};

export default Login;
