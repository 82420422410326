function fallbackCopyTextToClipboard(text, resolve, reject) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
    resolve();
  } catch (err) {
    reject();
  }

  document.body.removeChild(textArea);
}

function copyTextToClipboard(text) {
  return new Promise((resolve, reject) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text, resolve, reject);
    }
    navigator.clipboard.writeText(text).then(
      () => {
        resolve();
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.error('Async: Could not copy text: ', err);
        reject();
      }
    );
  });
}

export default copyTextToClipboard;
